import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "../../stylesheets/life.scss";
import Pageheader from "../../components/pageheader";


const AboutPosts = ({ data }) => {
  const aboutPosts = data.allContentfulAbout.edges;
  const { heroImage, title, body } = data.contentfulPageHeader;


  const headerTitle = data.contentfulPageHeader.title;
  const headerDesc = data.contentfulPageHeader.body.body;
 const headerImage = data.contentfulPageHeader.heroImage.fluid.src;
 

  return (

    <Layout>
      <SEO title={headerTitle} pageDescription={headerDesc} heroImage={headerImage} keywords={[`gatsby`, `application`, `react`]} />
      <Pageheader title={title} image={heroImage} body={body} />
      <div className="container">
       
          {aboutPosts.map(({ node: post }) => (
             <div key={post.id}  className="row jobdescriptions">
               <div className="col-md-1">
                 <img src={post.logo.file.url} alt={post.jobTitle} className="img-fluid"/>
                 </div>
            <div className="col-md-11">
              <h2>{post.company}</h2>
              <p className="jobTitle">{post.jobTitle}</p>
              <p className="date">{post.startDate} - {post.endDate !== null ? post.endDate : 'Present'}</p>
           <div className="description"
              dangerouslySetInnerHTML={{
                __html: post.jobDescription.childMarkdownRemark.html,
              }}
            ></div>
     
            </div>
            </div>
          ))}
     
      </div>
    </Layout>
  );
};
export default AboutPosts;
export const query = graphql`
  query AboutPageQuery {
    allContentfulAbout(limit: 1000, sort: {order: DESC, fields: startDate}) {
            edges {
        node {
          jobTitle
        endDate(formatString: "MMMM YYYY")
        company
        jobDescription {
          childMarkdownRemark {
            html
          }
        }
        logo {
          file {
            url
          }
        }
        startDate(formatString: "MMMM YYYY")
        }
      }
    }
    contentfulPageHeader(title: {eq: "justin abercrombia"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        fluid(maxWidth: 2000, quality: 100) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }

`;
